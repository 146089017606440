'use client'; // Error components must be Client Components

import { useEffect } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { Button } from '@/app/components/Button';
import { EventTypes, useLogEvent } from '@/app/hooks/useLogEvent';
import { ParsedText } from '@/app/components/ParsedText';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { handleZendeskShow } from '@/lib/utils';

export default function Error({
  error,
  reset,
  disableHeader = false,
}: {
  error: Error & { digest?: string };
  reset?: () => void;
  disableHeader?: boolean;
}) {
  const partner = usePartner();
  const { t } = useTranslation();
  const logError = useLogEvent(EventTypes.ERROR);
  const partnerSettings = getPartnerSettings(partner);
  if (process.env.NODE_ENV === 'development') {
    console.log(error);
  }

  useEffect(() => {
    logError(error);
    handleZendeskShow({ minWidth: 768, timeout: 2000 });
  }, [error]);

  return (
    <div className="px-16 pt-16 max-w-[422px] box-content">
      {!disableHeader && (
        <h1 className="text-lg text-core-8 mb-8">
          {t('error-boundary-header')}
        </h1>
      )}
      <p className="text-core-7 mb-8 text-sm">
        <ParsedText
          htmlString={t('error-boundary-copy', {
            contactPhoneNumber: partnerSettings?.contactPhoneNumber ?? '',
          })}
        />
      </p>
      {reset && (
        <div>
          <Button
            type="button"
            onclick={() => reset?.()}
            mixpanelButtonText={'Page refresh after error boundary hit'}
            id="businessDetailStep1"
          >
            {t('common-button-try-again')}
          </Button>
        </div>
      )}
    </div>
  );
}
